.footer {
  background: #242629;
  color: $color-white;

  .footer-top {
    display: flex;
    border-bottom: 1px solid #3B4047;
    padding: 32px 0;
    .logo-social-links {
      .social-icons {
        display: flex;
        width: 180px;
        height: auto;
        flex-wrap: wrap;
        gap: 16px;
        padding-left: 14px;
        margin-top: 24px;
      }
      .social-icon {
        display: flex;
width: 40px;
height: 40px;
padding: 8px;
justify-content: center;
align-items: center;
border-radius: 12px;
border: 1px solid #3B4047;
background: #292D32;
cursor: pointer;
      }
    }

    .links-section {
      margin-left: 120px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .customer-support {
      display: flex;
      height: 32px;
      align-items: center;
      gap: 8px;
    }
    .quick-links {
      line-height: 20px;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .link-section-heading {
        font-weight: 600;
        text-transform: uppercase;
        svg {
          height: 17px !important;
          width: 16px !important;
        }
      }
      .link-name {
        font-weight: 500;
        text-decoration: none;
        color: #fff;
        svg {
          height: 17px !important;
          width: 16px !important;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    padding: 2rem 7rem;
    justify-content: center;
    &__content {
      display: flex;
      justify-content: space-between;
      max-width: $screen-xl;
      width: 100%;
    }

    &__list {
      list-style: none;
      display: flex;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    &__link {
      text-decoration: none;
      color: $color-white;
      display: inline-block;
      font: $regular-weight 1.4rem/2.1rem Poppins;
      letter-spacing: -0.03px;
    }
  }
}

@media(max-width:768px) {
  .footer {
  .footer-top {
    padding: 5rem 2.2rem;
  
    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    &__text {
      &--primary {
        text-align: center;
      }
    }
    &__input {
      width: calc(100vw - 60px);
      max-width:30rem;
    }

    &__info {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    &__info-box {
      &--label {
        margin-bottom: 1.7rem;
        margin-top: 4.5rem;
        text-align: center;
      }

      &--text {
        text-align: center;
      }

      &-long--text {
        width: 100%;
        text-align: center;
        max-width: 22rem;
      }
    }

    &__social-icons {
      display: flex;
      align-items: flex-end;
      margin: 6rem 0 3rem;
      flex-wrap: wrap;

      @media(max-width:380px) {
        width: 20rem;
        & a {
          flex-basis: calc(33.33% - 2rem);
          margin: 1rem !important;
          display: flex;
          justify-content: center;
        }
      }
    }

    &__social-icon {
      height: 4rem;
      width: 4rem;
      background-repeat: no-repeat;
      cursor: pointer;
      background-size: contain;
    }
  }
  
  .footer-top__content {
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom {
    padding: 2rem 7rem;

    &__right {
      display: none;
    }
    &__left {
      text-align: center;
    }
  }
  .footer-bottom__content {
    display: block;
}
}
}



.footer-bottom-ribbon {
  display: flex;
  justify-content: space-between;
  color: #D5D6E1;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
height: 68px;
width: 100%;
  .footer-bottom__link {
    font-size: 14px;
    font-weight: 500;
  }
}

.footer-app-download {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .link-name{
    width: 188px;
    line-height: 0;
    // height: auto;
    img {
      width: 100%;
    }
  }
}

// @media(min-width:$screen-xl) {
//   .footer-bottom__content, .footer-top__content {
//     max-width: $screen-xl;
//     width: $screen-xl;
//   }
// }


.footer-content-container {
  display: flex;
  width: 100vw;
  justify-content: center;
  .footer-content {
    max-width: 1200px;
    margin: 0 24px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .footer-content-container .footer-content {
    margin-left: 0;
    margin-right: 0;
    padding: 0 24px;
  }
}
@media screen and (max-width: 1000px) {
  .footer-content-container .footer-content {
    padding: 0 16px;
  }
}
@media screen and (max-width: 840px) {
  .footer {
    .footer-top {
      .links-section {
        margin-left: 80px;
        gap: 40px;
      }
    }
  }
}
@media screen and (max-width: 775px) {
  .footer {
    .footer-top {
      padding: 0;
      padding-top: 24px;
      padding-bottom: 32px;
      flex-direction: column;
      .links-section {
        margin-left: 0px;
        margin-top: 0;
        padding-top: 32px;
        gap: 40px;
      }
      .logo-social-links {
        padding-bottom: 32px;
        border-bottom: 1px solid #3B4047;
        .social-icons {
          margin: 0;
          width: 100%;
          padding: 0;
          margin-top: 16px;
        }
      }
      .footer-app-download {
        gap: 8px;
        a {
          width: 118px;
        }
      }
      .quick-links {
        gap: 16px;
      }
    }
  }
}
@media screen and (max-width: 510px) {
  .footer {
    .footer-top {
      padding: 0;
      padding-top: 24px;
      padding-bottom: 32px;
      flex-direction: column;
      .links-section {
        margin-left: 0px;
        margin-top: 0;
        padding-top: 32px;
        gap: 64px;
        row-gap: 32px;
        justify-content: start;
        flex-wrap: wrap;
      }
      .logo-social-links {
        padding-bottom: 32px;
        border-bottom: 1px solid #3B4047;
        .social-icons {
          margin: 0;
          width: 100%;
          padding: 0;
          margin-top: 16px;
        }
      }
      .footer-app-download {
        position: absolute;
        right: 0;
        gap: 8px;
        a {
          width: 118px;
        }
      }
      .quick-links {
        gap: 12px;
      }
      .quick-links:nth-child(3) {
        position: relative;
        border-top: 1px solid #3B4047;;
        padding-top: 32px;
        width: 100%;
      }
    }
    .footer-bottom-ribbon {
      flex-direction: column-reverse;
    gap: 8px;
    height: auto;
    padding-top: 32px;
    padding-bottom: 24px;
    }
  }
}