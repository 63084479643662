$font-size: 1.6rem;

$color: #ccc;

$color-dark-grey-main: #292D32;
$color-dark-grey-1: #36373a;
$color-dark-grey-2: #2a2a2a;
$color-dark-grey-3: #a2a2a2;
$color-dark-grey-4: #808080;
$color-dark-grey-5: #22262a;
$color-dark-grey-6: #888888;
$color-dark-grey-7: #262626;
$color-dark-grey-8: #686A70;
$color-grey-light-9: #00000024;

$color-light-yellow-bg: #F2EBDA;
$color-light-yellow-1: #e5bc5a;
$color-light-yellow-2: #EEDEB8;
$color-dark-yellow-1: #d5ac4f;
$color-dark-yellow-2: #d3ac4f;

$color-grey-light-1: #f6f6f6;
$color-grey-light-2: #c8c9c9;
$color-grey-light-3: #e4e4e4;
$color-grey-light-4: #f6f6f6;
$color-grey-light-5: #ececec;

$color-blue-light-1: #389bff;
$color-red: #f44336;

$color-white: #fff;

$white: #fff;

$light-weight: 300;

$regular-weight: 400;

$medium-weight: 500;

$semi-bold-weight: 600;

$bold-weight: 700;

$extra-bold-weight: 800;

$font-name: Poppins;

$bp-mega: 90.62em; // 1450/16 = 90.62em, 1em = 16px
$bp-mega-semi: 81.25em; // 1300/16 = 81.25em, 1em = 16px

$bp-mega-medium: 84.37em; // 1350/16 = 84.375‬em, 1em = 16px

$bp-largest: 75em; // 1200/16 = 75em, 1em = 16px
$bp-large: 68.75em; // 1100/16 = 68.75em, 1em = 16px

$bp-high: 62.5em; // 1000/16 = 62.5em, 1em = 16px
$bp-medium: 56.25em; // 900/16 = 56.25em, 1em = 16px
$bp-semi-medium: 50em; // 800/16 = 50em, 1em = 16px
$bp-small: 37.5em; // 600/16 = 37.5em, 1em = 16px
$bp-smallest: 31.25em; // 500/16 = 31.25em, 1em = 16px

$screen-xl: 1400px;
$screen-xxl: 1500px;
