@import "./variables";
@import "./base";
@import "./typography";
@import "./mixins";

// @import "../containers/AutomaticGates/automatic-gates.scss";
// @import "../containers/OtherProducts/other-products.scss";
// @import "../containers/HomePage/home-page.scss";
// @import "../containers/InteractiveHome/interactive-home.scss";
// @import "../containers/KeylessEntry/keyless-entry.scss";
// @import "../containers/MoodControlledLightening/mood-controlled-lighting.scss";
// @import "../containers/SecurityAndSurveillance/security-and-surveillance.scss";
// @import "../containers/SolarEnergy/solar-energy.scss";
// @import "../containers/WaterEnergyManagement/water-energy-management.scss";
// @import "../containers/ProductPage/product-page.scss";
// @import "../containers/ShopPage/shop-page.scss";
// @import "../containers/CartPage/cart-page.scss";
// @import "../containers/CustomerInfo/customer-info.scss";
// @import "../containers/PaymentMethod/payment-method.scss";
// @import "../containers/Orders/orders.scss";
// @import "../containers/Favourites/favourites.scss";
// @import "../containers/MyAccount/my-account.scss";

@import "../components/Navbar/navbar.scss";
@import "../components/Footer/footer.scss";
// @import "../components/AuthModal/auth-modal.scss";
// @import "../components/ProductCard/product-card.scss";
// @import "../components/InputWithLabel/input-with-label.scss";
// @import "../components/CartSelect/cart-select.scss";
// @import "../components/OrderPlaced/order-placed.scss";
// @import "../components/OrderFailed/order-failed.scss";
// @import "../containers/ContactUs/contact-us.scss";

@media (max-width: 900px) {
    iframe[data-testid="bubble_iframe"] {
        right: 2px !important;
        bottom: 90px !important;
    }
  }