*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  @media only screen and (min-width: 1451px) {
    font-size: 73%;
  }
  @media only screen and (max-width: $bp-mega) {
    font-size: 62.5%;
  }

  @media only screen and (max-width: $bp-largest) {
    font-size: 55%;
  }

  @media only screen and (max-width: $bp-large) {
    font-size: 50%;
  }

  @media only screen and (max-width: $bp-high) {
    font-size: 45%;
  }

  @media only screen and (max-width: 768px) {
    font-size: 55%;
  }
}

body {
  box-sizing: border-box;
}

.Toastify__toast-container {
  width: auto !important;
}
