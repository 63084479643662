.navbar-placeholder {
  height: 0;
}
.sticky-header ~ .navbar-placeholder {
  height: 82px;
  @media screen and (max-width: 1000px) {
    height: 74px;
  }
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3;
  transition: 0.8s all;
}

  .top-bar {
    width: 100vw;
    // padding: 10px 7rem;
    min-height: 44px;
    background-color: $color-light-yellow-bg;
    display: flex;
    align-items: center;

    &__content {
      padding: 12px 24px;
      text-align: center;
      line-height: 21px;
      font-size: 14px;
      max-width: $screen-xl;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: auto;
      .bold {
        font-weight: bold;
      }
    }

    .toggle {
      margin-right: auto;
      .MuiFormControlLabel-label {
        color: white;
      }
    }
    a {
      font-size: 14px;
      color: $color-dark-grey-main;
      margin: 0 1.5rem;
      text-decoration: none;
      outline: none;
      cursor: pointer;
    }
    a.selected {
      color: $color-dark-yellow-1;
    }
    a:last-child {
      margin-right: 0;
    }
  }

  .hamburger-container {
    display: none;
  }

  .bottom-bar {
    // padding: 0 7rem;
    height: 82px;
    @include flex-center;
    background-color: #ffffff;
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 3;
    width: 100vw;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;


    .mobile-header {
      display: none;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
      height: 74px;
    }

    &__content {
      max-width: 1200px;
      display: flex;
      @include flex-center;
      width: 100%;
      margin: 0 24px;
    }

    .logo-container {
      // margin-right: auto;
      height: 48px;
      cursor: pointer;
      display: flex;
      align-items: center;
      
      a {
        height: 100%;
        width: auto;
      }
    }
    .user-mobile-container,
    .cart-mobile-container {
      display: none;
    }
    .desktop-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .nav-items-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
    .header-left-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;
      .header-input-wrapper {
        position: relative;
        .header-input-icon {
          position: absolute;
          top:10px;
          left:14px;
          height: 24px;
        }
        .header-input-placeholder {
          position: absolute;
          font-size: 14px;
          color: #A2A3A9;
          line-height: 24px;
          top: 10px;
          left: 46px;
          pointer-events: none;
          span {
            font-weight: 500;
            color: $color-dark-grey-main;
          }
        }
        .header-input {
          border-radius: 24px;
          background: #F9F7F2;
          border: none;
          height: 44px;
          width: 312px;
          padding-left: 46px;
          padding-right: 12px;
          font-size: 14px;
          @media screen and (max-width: 1000px) {
            width: 280px;
          }
        }
        .header-input:active, .header-input:focus, .header-input:visited {
          border: 1px solid $color-dark-grey-main;
          outline: none;
        }
      }
    }
    .nav-item {
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: $color-dark-grey-main;
      position: relative;
      // padding: 2.1rem 1.5rem;
      // margin: 0 2rem;
      transition: color 200ms linear;
      @include flex-center;
      user-select: none;
      height: 26px;
      text-decoration: none;
      a {
        color: $color-dark-grey-1 !important;
        transition: color 200ms linear;
        text-decoration: none;
      }
    }
    .nav-item:hover,
    .selected {
      color: $color-dark-yellow-1;
      // border-bottom: 0.2rem solid $color-dark-yellow-1;
      font-weight: 600;
      ::before {
        content: "";
        position: absolute;
        left: calc(50% - 10px);
        bottom: -4px;
        height: 15px;
        width: 20px;
        background: radial-gradient(2650.49% 73.81% at 51.46% 50%, #D5AC4F 0%, #955F0F 100%);
        height: 2px;
        // border-bottom: 2px solid $color-dark-yellow-1;
        border-radius: 2px;
      }
      a {
        color: $color-dark-yellow-1 !important;
      }
      svg path {
        stroke: #D5AC4F;
        stroke-width: 2.2;
      }
    }

    .cart-container {
      position: relative;
      cursor: pointer;
      height: 24px;
    }
    img {
      height: 100%;
    }

    @media screen and (max-width: 1000px) {
      .nav-items-container {
        gap: 16px;
      }
      .logo-container {
        height: 36px;
      }
    }
  }

  .cart-items-count {
    position: absolute;
    width: 16px;
    height: 16px;
    background: #D5AC4F;
    color: white;
    top: 0px;
    right: -4px;
    border-radius: 50%;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 900px) {
      // padding-top: 0;
      // top: -0.8rem;
      // right: -0.8rem;
      // width: 1.8rem;
      // height: 1.8rem;
      // font-size: 0.9rem;
    }
  }

@media (max-width: 900px) {
  // .navbar-placeholder {
  //   height: 8rem;
  // }
  .hamburger-container {
      display: block;
      margin-right: 1.5rem;

      &:focus {
        outline: none !important;
      }

      .hamburger-react {
        outline: none !important;

        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;

        &:focus {
          outline: none !important;
        }
      }
  }

  .top-bar {
    &__content {
      font-size: 12px;
      line-height: 18px;
    }
  }
    
    .bottom-bar {
      height: 74px;
      &__content {
        .desktop-header {
          display: none;
        }
        .mobile-header {
          display: flex;
          padding: 0 16px;
        }
      }




      .nav-items-container {
        display: none;
      }
    }
  }


